import React from "react"
import SimpleLightBox from 'simple-react-lightbox'
import "@fortawesome/fontawesome-svg-core/styles.css"
import {config} from "@fortawesome/fontawesome-svg-core"

require("./src/style/bootstrap-grid.min.css");

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({element}) => (
    <SimpleLightBox>{element}</SimpleLightBox>
);

config.autoAddCss = false;